<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" width="850">
            <v-card class="px-4">
                <v-card-title class="mb-4">
                    <span class="text-h4 font-weight-bold">选择课程包</span>
                </v-card-title>
                <!-- <v-row justify="center"> -->
                <v-row>
                    <v-col cols="12" md="10" offset="1">
                        <v-data-table
                            v-model="selected"
                            :headers="headers"
                            :items="datas"
                            item-key="topic_id"
                            :items-per-page="10"
                            :items-per-page-options="[5, 10, 15, -1]"
                            class="elevation-2 row-pointer"
                            show-select
                            :single-select="singleSelect"
                            :hide-default-footer="datas.length <= 10"
                            :footer-props="{
                                showFirstLastPage: true,
                                itemsPerPageText: '每页行数',
                                pageText: '',
                                'items-per-page-all-text': '全部',
                            }"
                            @click:row="selectRow"
                        >
                            <template v-slot:item.name="{ item }">
                                <div
                                    class="font-weight-medium"
                                >
                                    {{ item.name }}
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer class="mb-4"></v-spacer>
                    <!-- <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="grey"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="green darken-1"
                        text
                        @click="submit"
                    >
                        确认
                    </v-btn> -->
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { getAdminTopicList } from "@/api/admin";
import { getTypeColor } from '@/utils/common';

export default {
    name: "SelectTopicDlg",

    data: function () {
        return {
            dialog: false,

            headers: [
                { text: "课程包名称", value: "name", align: "start" },
                { text: "课程包说明", value: "desc", align: "start" },
            ],
            datas: [],

            singleSelect: true,
            selected: [],
            
            type: '',
            groupId: '',
            
        };
    },
    computed: {},
    created() {},
    activated() {
        // this.loadData();
    },
    mounted() {
        // this.loadData();
    },
    watch: {},
    methods: {
        getTypeColor,
        loadData() {
            getAdminTopicList('ALL').then((response) => {
                // console.log(response.data)
                this.datas = response.data
                
            }).catch(function (err) {
                console.log(err);
            });
        },
        openDlg(type = '', groupId = '', selected = []) {
            // console.log(groupId);
            this.type = type
            this.groupId = groupId
            this.selected = selected
           
            this.dialog = true

            this.loadData();
        },
        cancel() {
            this.dialog = false
        },
        submit() {
            // console.log(this.selected)
            if (this.selected.length == 1) {
                this.$emit("submit", {
                    topicId: this.selected[0].topic_id,
                    topicName: this.selected[0].name,
                })
            }
            this.dialog = false
        },
        selectRow(row) {
            // console.log(row)
            this.$emit("submit", {
                topicId: row.topic_id,
                topicName: row.name,
            })
            this.dialog = false
        }
    },
    components: {},
};
</script>

<!-- 让v-data-table行中的cursor显示pointer -->
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
