<template>
    <v-container fluid class="px-8" >
        <v-row>
            <v-col class="d-flex" >
                <v-btn
                    tile
                    dark
                    color="purple lighten-1"
                    class="font-weight-bold text-body-1 mb-6"
                    @click.stop="openEditClassDlg()"
                >
                    <v-icon left>
                        mdi-plus-circle-outline
                    </v-icon>
                    创建班级
                </v-btn>
            </v-col>
        </v-row>

        <!-- 课程列表 -->
        <v-data-table
            :headers="headers"
            :items="datas"
            :items-per-page-options="[5, 10, 15, -1]"
            class="elevation-2 row-pointer"
            :hide-default-footer="datas.length <= 10"
            :footer-props="{
                showFirstLastPage: true,
                itemsPerPageText: '每页行数',
                pageText: '',
                'items-per-page-all-text': '全部',
            }"
            @click:row="clickRow"
        >
            <template v-slot:item.name="{ item }">
                <div class="td-line font-weight-medium">
                    <!-- <div class="active-name" @click.stop="goto(item.class_id)" > -->
                    <div class="active-hover" @click.stop="goto(item.class_id)" >
                        {{ item.name }}
                    </div>
                </div>
            </template>
            <template v-slot:item.topic_name="{ item }">
                <div class="td-line font-weight-medium">
                    <div class="active-hover" @click.stop="goto(item.class_id)" >
                        {{ item.topic_name && item.topic_name != "" ? item.topic_name : "未关联" }}
                    </div>
                </div>
            </template>
            <template v-slot:item.start_date="{ item }">
                {{ dateFormat('YYYY/mm/dd', item.start_date) }}
            </template>
            <template v-slot:item.end_date="{ item }">
                {{ dateFormat('YYYY/mm/dd', item.end_date) }}
            </template>
            <template v-slot:item.status="{ item }">
                <v-chip :color="item.status == 1 ? 'light-green lighten-1' : 'grey lighten-1'" small dark 
                        class="font-weight-medium"
                >
                    {{ getStatus(item.status) }}
                </v-chip>
            </template>
            <template v-slot:item.student_count="{ item }">
                <div class="font-weight-medium">
                    {{ item.student_count }}
                </div>
            </template>
            <template v-slot:item.price="{ item }">
                {{ getPrice(item.price) }}
            </template>
            <template v-slot:item.operate="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-1"
                            icon
                            height="32"
                            width="32"
                            outlined
                            x-small
                            elevation="2"
                            color="purple"
                            @click.stop="openEditClassDlg(item)"
                        >
                            <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                    </template>
                    <span>编辑班级</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-1"
                            icon
                            height="32"
                            width="32"
                            outlined
                            x-small
                            elevation="2"
                            color="red darken-1"
                            @click.stop="openConfirmDlg(item.class_id)"
                        >
                            <v-icon dark> mdi-trash-can </v-icon>
                        </v-btn>
                    </template>
                    <span>删除班级</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <!-- 创建/编辑班级信息 -->
        <EditClassDlg ref="editClassDlg" @submit="submitClass" @select="selectTopic" />
        <!-- 在班级中关联课程包 -->
        <SelectTopicDlg ref="selectTopicDlg" @submit="submitSelectTopic" />

        <ConfirmDlg
            ref="delClassConfirmDlg"
            title="删除班级"
            text="确认 删除 当前班级么？"
            keyword=" 删除 "
            @confirm="delClassConfirm"
        />

    </v-container>
</template>

<script>
import EditClassDlg from '@/views/components/dialog/EditClassDlg';
import SelectTopicDlg from "@/views/components/dialog/SelectTopicDlg";
import ConfirmDlg from '@/views/components/dialog/ConfirmDlg';

import { getAdminClassList, editAdminClass, delAdminClass } from "@/api/admin";
import { getTypeColor } from '@/utils/common';
import { getTypeFirstLetter, dateFormat } from "@/utils/util";

export default {
    name: "ClassList",
    data() {
        return {
            headers: [
                { text: "班级名称", value: "name", align: "left", sortable: false, },
                { text: "班级说明", value: "desc", align: "left", sortable: false, },
                { text: "课程包名称", value: "topic_name", align: "left", sortable: false, },
                { text: "开始日期", value: "start_date", width: 90, align: "center", sortable: false, },
                { text: "结束日期", value: "end_date", width: 90, align: "center", sortable: false, },
                // { text: "上课时间", value: "time", width: 90, align: "center", sortable: false, },
                { text: "学生数", value: "student_count", width: 80, align: "center", sortable: false, },
                { text: "价格", value: "price", width: 80, align: "center", sortable: false, },
                // { text: "状态", value: "status", width: 80, align: "center", sortable: false, },
                { text: "操作", value: "operate", sortable: false, align: "center", width: 120},
            ],
            datas: [],
        };
    },
    mounted() {
    },
    activated() {
        this.loadData();
    },
    methods: {
        getTypeColor,
        getTypeFirstLetter,
        dateFormat,
        getPrice(price) {
            if (price == 0) {
                return "免费"
            }
            return price
        },
        getStatus(status) {
            if (status == 0) {
                return "未开课"
            } else if (status == 1) {
                return "已开课"
            }
        },
        loadData() {
            getAdminClassList().then((response) => {
                // console.log(response.data);
                this.datas = response.data;
            
            }).catch(function (err) {
                console.log(err);
            });
        },
        goto(id) {
            this.$router.push({path: '/education-mgr/class-list/class', query: {id: id}});
        },
        clickRow(item) {
            this.goto(item.class_id);
        },

        openEditClassDlg(classData = null) {
            this.$refs.editClassDlg.openDlg(classData);
        },
        openConfirmDlg(classId) {
            this.$refs.delClassConfirmDlg.openDlg(classId);
        },

        delClassConfirm(classId) {
            delAdminClass(classId).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    for (let i = 0; i < this.datas.length; i++) {
                        if (this.datas[i].class_id == classId) {
                            this.datas.splice(i, 1)
                            break
                        }
                    }

                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "删除班级成功",
                    });

                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "删除班级失败",
                    });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },
        submitClass(newClass) {
            editAdminClass({
                classId: newClass.class_id,
                topicId: newClass.topic_id,
                name: newClass.name,
                desc: newClass.desc,
                cover: newClass.cover,
                startDate: newClass.start_date,
                endDate: newClass.end_date,
                classTime: newClass.class_time,
                isCharge: newClass.is_charge,
                price: newClass.price,
                status: 0,
            }).then((response) => {
                // console.log(response.data)
                if (response.msg == 'SUCCESS') {
                    this.$store.dispatch('web/SetAlert', {type: "success", msg: "创建班级成功"});
                    this.loadData()

                } else {
                    this.$store.dispatch('web/SetAlert', {type: "error", msg: "创建班级失败"});
                }
            }).catch(function (err) {
                console.log(err);
            });
        },

        selectTopic() {
            this.$refs.selectTopicDlg.openDlg();
        },
        submitSelectTopic(selected) {
            // console.log(selected)
            this.$refs.editClassDlg.setSelected(selected)
        }
    },

    components: {
        EditClassDlg,
        SelectTopicDlg,
        ConfirmDlg,
    }
};
</script>

<style lang="scss" scoped>
.option-area {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .option-item {
        width: 150px;
    }
}
.btn-font {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
}

.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.td-line {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    height: unset !important;
    .active-name {
        padding: 8px 25px;
        border-radius: 50px;
        // background-color: #efefef;
        background-color: #fff;
        &:hover {
            cursor: pointer;
            background-color: #fff;
            border-radius: 50px;
        }
    }
    .active-hover {
        cursor: pointer;
    }
}
</style>

<!-- 让v-data-table行中的cursor显示pointer -->
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
