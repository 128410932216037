<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent width="700">
            <v-card class="px-4">
                <v-card-title class="mb-4">
                    <span class="text-h4 font-weight-bold">创建班级</span>
                </v-card-title>
                <!-- <v-row justify="center"> -->
                <!-- <v-row> -->
                <div class="content mx-6">
                    <div class="col-left">
                        <v-img
                            :src="previewImg"
                            height="220"
                            width="200"
                            class="grey darken-4"
                        ></v-img>
                        <v-file-input
                            class="mt-5"
                            v-model="file"
                            required
                            :rules="rules"
                            accept="image/png, image/jpeg, image/jpg"
                            label="课程包封面图片"
                            small-chips
                            @change="uploadFile"
                        ></v-file-input>
                    </div>
                    <div class="col-right">
                        <form class="">
                            <v-text-field
                                label="班级名称"
                                v-model="name"
                                required
                                :error-messages="nameErrors"
                                :counter="20"
                            ></v-text-field>
                            
                            <v-text-field
                                label="班级说明"
                                v-model="desc"
                                required
                                :error-messages="descErrors"
                                :counter="20"
                            ></v-text-field>

                            <div class="form-row between mt-4">
                                <div class="one mr-8">
                                    <v-menu
                                        v-model="menuStartDate"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="start_date"
                                            label="开始日期"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            :day-format="getDay"
                                            v-model="start_date"
                                            no-title
                                            @input="menuStartDate = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </div>
                                <div class="one">
                                    <v-menu
                                        v-model="menuEndDate"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="end_date"
                                            label="结束日期"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            :day-format="getDay"
                                            v-model="end_date"
                                            no-title
                                            @input="menuEndDate = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </div>
                            </div>

                            <div class="form-row start mt-3" style="height: 60px">
                                <div class="form-label mr-4">当前课程</div>
                                <div v-if="!topic_id || topic_id == ''">
                                    <v-btn
                                        outlined
                                        rounded
                                        color="purple"
                                        @click.stop="select"
                                    >
                                        <v-icon left> mdi-plus </v-icon>
                                        选择课程包
                                    </v-btn>
                                </div>
                                <div style="display: flex" v-else>
                                    {{ topic_name }}
                                    <v-icon 
                                        dark
                                        color="grey"  
                                        class="ml-2"
                                        @click.stop="clearCurTopic()"
                                    >
                                        mdi-close-circle
                                    </v-icon>
                                </div>
                            </div>

                            <div class="form-row start" style="height: 70px">
                                <div class="form-label mr-4">付费课程</div>
                                <v-switch
                                    v-model="is_charge"
                                    inset
                                    dense
                                    class="mr-8"
                                ></v-switch>
                                <v-text-field label="课程价格" 
                                    class="mr-0"
                                    v-model="price"
                                    required
                                    v-if="is_charge"
                                ></v-text-field>
                            </div>

                        </form>
                    </div>
                <!-- </v-row> -->
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="grey"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="green darken-1"
                        text
                        @click="submit()"
                    >
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import qiniu from '@/utils/qiniu'
import { getImgUrl } from "@/utils/util"
import { deleteImage } from "@/api/admin";

export default {
    name: "EditClassDlg",

    mixins: [validationMixin],
    validations: {
        name: { required, maxLength: maxLength(30) },
        desc: { required },
    },

    data: function () {
        return {
            dialog: false,

            // 数据
            class_id: "",
            name: "",
            desc: "",
            class_time: "",
            topic_id: "",
            topic_name: "",
            is_charge: 0,
            price: "",
            previewImg: "",

            start_date: null,
            menuStartDate: false,
            end_date: null,
            menuEndDate: false,

            file: null,
            // isUploading: false,
            rules: [
                value => !value || value.size < 2000000 || '图片大小要求小于2MB',
            ],
        };
    },
    computed: {
        nameErrors() {
            const errors = [];
            if (!this.$v.name.$dirty) return errors;
            !this.$v.name.required && errors.push("请填写班级名称");
            return errors;
        },
        descErrors() {
            const errors = [];
            if (!this.$v.name.$dirty) return errors;
            !this.$v.desc.required && errors.push("请填写班级说明");
            return errors;
        },
    },
    created() {},
    activated() {},
    methods: {
        getDay(date) {
            return new Date(date).getDate()
        },
        getDate(date) {
            if (date != "") {
                return date.substr(0, 10)
            }
            return ""
        },
        getToday() {
            return new Date().toISOString().substr(0, 10)
        },
        openDlg(classData = null) {
            this.clear()

            if (classData) {
                this.class_id = classData.class_id
                this.name = classData.name
                this.desc = classData.desc
                this.cover = classData.cover
                this.start_date = this.getDate(classData.start_date)
                this.end_date = this.getDate(classData.end_date)
                this.class_time = classData.class_time
                this.topic_id = classData.topic_id
                this.topic_name = classData.topic_name
                this.is_charge = classData.is_charge
                this.price = classData.price
                this.previewImg = getImgUrl(classData.cover)
            }
            // 默认图片
            if (this.previewImg == "") {
                this.previewImg = require('@/assets/images/admin/default-cover.jpeg')
            }

            this.dialog = true
        },
        clear() {
            this.$v.$reset()

            this.class_id = ""
            this.name = ""
            this.desc = ""
            this.cover = ""
            this.start_date = this.getToday()
            this.end_date = this.getToday()
            this.class_time = ""
            this.topic_id = ""
            this.topic_name = ""
            this.is_charge = 0
            this.price = ""
            this.previewImg = ""
            this.file = null
        },
        cancel() {
            this.clear()
            this.dialog = false
        },
        submit() {
            this.$v.$touch()

            if (!this.$v.$invalid) {
                // 用户确认后调用submit回调，将新的参数传递出去
                this.$emit("submit", {
                    class_id: this.class_id,
                    name: this.name,
                    desc: this.desc,
                    cover: this.cover,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    class_time: this.class_time,
                    topic_id: this.topic_id,
                    topic_name: this.topic_name,
                    is_charge: this.is_charge,
                    price: this.price,
                })
                this.dialog = false
            }
        },
        select() {
            this.$emit("select")
        },
        setSelected(selected) {
            this.topic_id = selected.topicId
            this.topic_name = selected.topicName
        },
        clearCurTopic() {
            this.topic_id = ""
            this.topic_name = ""
        },

        // 上传完成后回调：
        async uploadDoneCallback(newCover) {
            // 1.删除之前的图片
            if (this.cover) {
                await deleteImage(this.cover)
            }

            // 2.保存新图片id
            this.cover = newCover

            // 3.恢复上传
            // this.isUploading = false
        },
        // 用户选择图片后触发
        async uploadFile() {
            // console.log('got upload file')
            // console.log(this.file)
            if (!this.file) {
                // this.isUploading = false
                return
            }

            // this.isUploading = true

            if (this.file) {
                // 展示预览图
                this.previewImg = URL.createObjectURL(this.file)

                // 上传到七牛
                await qiniu.uploadFile(this.file, 'images', this.uploadDoneCallback)
            }
        },
    },
    components: {},
};
</script>

<style lang="scss" scoped>
.form-row {
    width: 100%;
    display: flex;
    align-items: center;
    &.between {
        justify-content: space-between;
    }
    &.start {
        justify-content: flex-start;
    }
    .one {
        flex: 1;
    }

    .form-label {
        font-size: 14px;
        color: #bbb;
    }
}
</style>

<!-- 临时添加，解决样式问题 -->
<style lang="scss">
.v-date-picker-header {
    .v-btn {
        background-color: unset !important;
        color: unset !important;
    }
}
.content {
    display: flex;
    justify-content: center;
    align-content: flex-start;
    .col-left {
        margin-right: 36px;
        // margin-top: 15px;
    }
    .col-right {
        // margin-top: -18px;
    }
}
</style>
